<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
      <bookmarks />
      <feather-icon
              class="cursor-pointer ml-1 text-secondary"
              icon="RefreshCwIcon"
              size="21"
              @click="++state.reload"
              v-tooltip="'دریافت مجدد اطلاعات'"
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{state.userInfo.firstName + ' ' + state.userInfo.lastName}}
            </p>
            <span class="user-status">{{state.userInfo.rolePersian}}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/avatar.jpg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{path:'/admin-profile'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>حساب کاربری</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'Chat'}">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>تیکت ها</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>خروج</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@/@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Bookmarks from '@/@core/layouts/components/app-navbar/components/Bookmarks'

export default {
  components: {
    Bookmarks,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods:{
    logout(){
      localStorage.clear('token')
      this.state.token = ''
      this.state.gotten = false
      this.$axios.defaults.headers.common['Authorization'] = this.state.token
      this.$router.push('/login')
    }
  },
}
</script>
